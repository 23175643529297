/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
export const onClientEntry = () => {
    function decideSliderButtons(element) {
        
        element.classList.remove("can-scroll-left");
        element.classList.remove("can-scroll-right");
        element.classList.remove("can-scroll-both");
        if (element.scrollWidth <= element.clientWidth) {
            return;
        }

        if (element.scrollLeft < (element.scrollWidth - element.clientWidth)) {
            element.classList.add("can-scroll-right");
        }

        if (element.scrollLeft > 0) {
            element.classList.add("can-scroll-left");
        }

        if (element.scrollLeft > 0 && element.scrollLeft < (element.scrollWidth - element.clientWidth)) {
            element.classList.remove("can-scroll-left");
            element.classList.remove("can-scroll-right");
            element.classList.add("can-scroll-both");
        }

    }
    window.onload = () => { 
        const matches = document.querySelectorAll("table");
        for (let i = 0; i < matches.length; i++) {
            decideSliderButtons(matches[i]);
            matches[i].addEventListener("scroll", function() {
                decideSliderButtons(this);
                console.log("you scrolled table number " + i);
              });
          }
    }
  }